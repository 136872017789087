// extracted by mini-css-extract-plugin
export var blasone = "Stores-module--blasone--qPYpT";
export var dungeondice = "Stores-module--dungeondice--VyBRy";
export var fantasia = "Stores-module--fantasia--8W4uM";
export var gamefound = "Stores-module--gamefound--DWSrR";
export var gatsbyRespImageBackgroundImage = "Stores-module--gatsby-resp-image-background-image--uy0tr";
export var gatsbyRespImageImage = "Stores-module--gatsby-resp-image-image--DufCD";
export var gatsbyRespImageWrapper = "Stores-module--gatsby-resp-image-wrapper--izkQv";
export var getyourfun = "Stores-module--getyourfun--Y6Vl2";
export var kickstarter = "Stores-module--kickstarter--XrXcd";
export var lsgiochi = "Stores-module--lsgiochi--+-ksf";
export var magicmerchant = "Stores-module--magicmerchant--b2U4O";
export var mainContent = "Stores-module--main-content--guwDB";
export var mse = "Stores-module--mse--IDKXx";
export var readMorePosts = "Stores-module--read-more-posts--KhUxy";
export var stores = "Stores-module--stores--yO4Jr";
export var weega = "Stores-module--weega--qn1RS";